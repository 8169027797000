import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { Col, ReadOnlyFormValue, Row } from '@/components/atoms'
import { AppointmentTypeSelector } from '@/components/molecules'
import { AppointmentTypes } from '@/types/enums/transactionDetails'

interface IGeneralSectionFields {
  date: Dayjs
  appointment_type: AppointmentTypes
}

const GeneralFormSection: FC = () => {
  const { watch, setValue } = useFormContext<IGeneralSectionFields>()

  const appointmentType = watch('appointment_type')

  const onAppointmentTypeChange = (value: AppointmentTypes) => {
    setValue('appointment_type', value)
  }

  return (
    <Col gap={20}>
      <Row gap={16}>
        <ReadOnlyFormValue
          title="Date"
          value={dayjs(watch('date')).format('MM/DD/YYYY')}
        />

        <ReadOnlyFormValue
          title="Time"
          value={dayjs(watch('date')).format('hh:mm A')}
        />
      </Row>

      <AppointmentTypeSelector
        value={appointmentType}
        onChange={onAppointmentTypeChange}
      />
    </Col>
  )
}

export default GeneralFormSection
