import * as yup from 'yup'

import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

const validations = {
  isCargoAssetVisible: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType, false)
  },

  isNotMinimized: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType, false) &&
      !isCargoAssetMinimized(puType)
  },

  isNotEmpty: {
    keys: ['appointment_type', 'power_unit_type', 'load_status'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      validations.isCargoAssetVisible.validate(appointmentType, puType) &&
      !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: ['appointment_type', 'power_unit_type', 'cargo_asset_asset_type'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      assetType: CargoAssetTypes
    ) =>
      validations.isCargoAssetVisible.validate(appointmentType, puType) &&
      isChassisIdVisible(puType, assetType)
  }
}

const CheckOutSchema = yup.object({
  // General Section
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  // Power Unit Section
  power_unit_owner_id: yup.string().required('ID is required'),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes)),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string(),
  power_unit_carrier_name: yup.string(),
  power_unit_carrier_usdot: yup.string(),
  power_unit_vin: yup.string(),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses)),
  power_unit_fuel_type: yup.mixed<FuelTypes>().oneOf(Object.values(FuelTypes)),
  account_name: yup.string(),

  // Driver Section
  driver_first_name: yup.string(),
  driver_last_name: yup.string(),
  driver_license_number: yup.string(),
  driver_phone_number: yup.string(),
  driver_license_state: yup.string(),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .oneOf(Object.values(CargoAssetTypes)),
  cargo_asset_owner_id: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) => schema.required('ID is required'),
    otherwise: (schema) => schema
  }),
  cargo_asset_carrier_name: yup.string(),
  chassis_id: yup.string().when(validations.isChassisVisible.keys, {
    is: validations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup.string().when(validations.isCargoAssetVisible.keys, {
    is: validations.isCargoAssetVisible.validate,
    then: (schema) => schema.required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipment_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired()
})

export default CheckOutSchema
