import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IJwt,
  IUpdatePortalsPayload,
  IUserPortalsListItem,
  IUserStore
} from '@/features/auth/types'
import { jwtDecode } from 'jwt-decode'
import { PortalTypes } from '@/types/enums/global'

const initialState: IUserStore = {
  me: undefined,
  selectedPortal: undefined,
  authToken: undefined,
  org: undefined,
  portals: []
}

export const userStore = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, action: PayloadAction<string>) => {
      const { payload } = action

      const decodedJwt: IJwt = jwtDecode(payload)

      const {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture,
        organization_id,
        organization_subdomain,
        organization_name
      } = decodedJwt

      state.authToken = payload
      state.org = {
        organization_id,
        organization_name,
        organization_subdomain
      }
      state.me = {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture
      }
    },

    changeUserName: (
      state,
      action: PayloadAction<{
        first_name: string
        last_name: string
        name: string
      }>
    ) => {
      const { first_name, last_name, name } = action.payload

      if (state.me) {
        state.me = {
          ...state.me,
          first_name,
          last_name,
          name
        }
      }
    },

    updatePortals: (state, action: PayloadAction<IUpdatePortalsPayload>) => {
      const { payload } = action
      const { sites, enterpriseEnabled, portalIdFromUrl } = payload

      const availablePortals: IUserPortalsListItem[] = sites.map((site) => ({
        id: site.id,
        name: site.display_name,
        type: PortalTypes.Site,
        // TODO: Replace with actual permissions from BE when it's ready
        permissions: {
          isr_record: true,
          gate_queue: true,
          gate_transaction: true,
          onsite_driver: true,
          onsite_cargo_asset: true,
          mismatches: true,
          video: true
        }
      }))

      if (enterpriseEnabled) {
        availablePortals.push({
          id: PortalTypes.Enterprise,
          name: 'Enterprise',
          type: PortalTypes.Enterprise,
          permissions: {
            users: true
          }
        })
      }

      state.portals = availablePortals

      if (!availablePortals.length) {
        state.selectedPortal = null

        return state
      }

      let selectedPortalEntity: IUserPortalsListItem | undefined = undefined

      if (portalIdFromUrl) {
        selectedPortalEntity = availablePortals.find(
          (portal) => portal.id === portalIdFromUrl
        )
      }

      if (!selectedPortalEntity) {
        const portalIdFromLocalStorage = localStorage.getItem('selected-portal')

        selectedPortalEntity = availablePortals.find(
          (portal) => portal.id === portalIdFromLocalStorage
        )
      }

      // If the selected portal is not found, default to the first available portal
      state.selectedPortal = selectedPortalEntity || availablePortals[0]

      localStorage.setItem('selected-portal', state.selectedPortal.id)
    },

    selectPortal: (
      state,
      action: PayloadAction<{ portalId: string; redirect: () => void }>
    ) => {
      const { portalId, redirect } = action.payload

      const newSelectedPortal = state.portals.find(
        (portal) => portal.id === portalId
      )

      if (newSelectedPortal) {
        if (state.selectedPortal?.type !== newSelectedPortal.type) {
          redirect()
        }

        state.selectedPortal = newSelectedPortal
        localStorage.setItem('selected-portal', newSelectedPortal.id)
      }
    },

    logoutUser: (state) => {
      state = initialState
    }
  }
})

export const userStoreReducer = userStore.reducer

export const {
  updateUserDetails,
  logoutUser,
  updatePortals,
  selectPortal,
  changeUserName
} = userStore.actions
