export enum PermissionResourceType {
  Platform = 'platform',
  Organization = 'organization',
  Site = 'site',
  Gate = 'gate',
  GateTransaction = 'gate_transaction',
  Lane = 'lane',
  CameraGroup = 'camera_group',
  OnsiteDriver = 'onsite_driver',
  OnsiteCargoAsset = 'onsite_cargo_asset',
  IsrRecord = 'isr_record'
}

export enum OrganizationRelation {
  Read = 'read',
  EnterprisePortal = 'enterprise_portal',
  ListMembers = 'list_members',
  DeleteMember = 'delete_member',
  DisableMember = 'disable_member',
  EnableMember = 'enable_member',
  InviteMember = 'invite_member',
  DeleteMemberInvite = 'delete_member_invite',
  ListMemberInvites = 'list_member_invites',
  ReadMemberProfile = 'read_member_profile',
  UpdateMemberProfile = 'update_member_profile',
  ListMemberSites = 'list_member_sites',
  UpdateMemberRoles = 'update_member_roles',
  GetMemberRoles = 'get_member_roles'
}
