import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Slide, Snackbar, SnackbarProps } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactNode, SyntheticEvent } from 'react'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

import styles from './Snackbar.module.scss'

interface IProps extends SnackbarProps {
  icon?: ReactNode
  onClose?: () => void
  type?: 'default' | 'success' | 'error'
}

const CustomSnackbar: FC<IProps> = (props) => {
  const {
    open,
    icon,
    message,
    onClose,
    autoHideDuration = 3000,
    type = 'default',
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    ...rest
  } = props

  const messageIcon = {
    default: icon,
    success: icon || <CheckCircleOutlineIcon />,
    error: icon || <ErrorOutlineIcon />
  }

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    onClose?.()
  }

  return (
    <Snackbar
      {...rest}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
    >
      <div className={clsx(styles.snackbar, styles[type])}>
        <Row items="center" gap={8}>
          {messageIcon[type]}

          <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
            {message}
          </Text>
        </Row>
      </div>
    </Snackbar>
  )
}

export default CustomSnackbar
