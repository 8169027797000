import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Snackbar } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CheckOutCargoAssetFormSection,
  CheckOutPowerUnitFormSection,
  DriverDetailsFormSection
} from '@/features/gate'
import { useCreateGateTransactionMutation } from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  SealMatchesPW
} from '@/features/gate/enums'
import { GateQueueEventWithExtraDetails, Lane } from '@/features/gate/types'
import {
  CheckOutSchema,
  getFormValuesFromQueueEvent,
  getSealMatchesPWValue,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import { useStore } from '@/store'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import { prepareSealNumbers } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface IProps {
  item?: GateQueueEventWithExtraDetails
  lane: Lane
  gateId: string
  closeModal: (removeFromMovedItems?: boolean) => void
}

const CheckOutModal: FC<IProps> = (props) => {
  const { item, gateId, lane, closeModal } = props
  const { imageUrl } = item || {}

  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()

  const formReturn = useForm<yup.InferType<typeof CheckOutSchema>>({
    resolver: yupResolver(CheckOutSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getFormValuesFromQueueEvent(item)
  })

  const { watch, trigger, getValues } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch('appointment_type'),
    watch('power_unit_type'),
    false
  )

  const onSubmit = async () => {
    const valid = await trigger()

    if (!valid || !org_id || !site_id) return

    const {
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_carrier_name,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number,
      driver_license_state,

      cargo_asset_owner_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      cargo_asset_carrier_name,
      cargo_asset_asset_type,
      load_status,
      shipment_number,
      seal_numbers,
      chassis_id,
      seal_matchPW
    } = getValues()

    const metadata: CreateGateTransactionMetadata = {
      lane_id: lane.id,
      transaction_type: DomainTransactionTypes.CheckOut,
      appointment_type,

      power_unit_type: power_unit_type || '',
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state: power_unit_license_plate_state || '',

      power_unit_carrier_usdot: power_unit_carrier_usdot || '',
      power_unit_carrier_name: power_unit_carrier_name || '',
      power_unit_carrier_MCNum:
        item?.metadata?.power_unit_carrier_MCNum?.[0] || '',

      power_unit_vin: power_unit_vin || '',
      power_unit_weight_class: power_unit_weight_class || '',
      power_unit_fuel_type: power_unit_fuel_type || '',
      power_unit_emission_type: power_unit_fuel_type
        ? emissionTypeByFuelMapper[power_unit_fuel_type]
        : '',
      account_name: account_name ? [account_name] : [],

      driver_first_name: driver_first_name || '',
      driver_last_name: driver_last_name || '',
      driver_license_number: driver_license_number || '',
      driver_phone_number: driver_phone_number || '',
      driver_license_state: driver_license_state || '',

      cargo_asset_owner_id: '',
      cargo_asset_license_plate_number: '',
      cargo_asset_license_plate_state: '',
      cargo_asset_carrier_name: '',
      cargo_asset_asset_type: '',
      load_status: '',
      shipment_number: '',
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      chassis_id: '',

      mismatch: false,
      inspection_completed: null
    }

    if (showCargoAsset) {
      metadata.load_status = load_status as LoadTypes

      if (!isCargoAssetMinimized(power_unit_type as PowerUnitTypes)) {
        metadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number as string
        metadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state as string
        metadata.cargo_asset_owner_id = cargo_asset_owner_id as string
        metadata.cargo_asset_carrier_name = cargo_asset_carrier_name || ''
        metadata.cargo_asset_asset_type = cargo_asset_asset_type || ''
      }

      if (!isAssetEmpty(load_status as LoadTypes)) {
        metadata.shipment_number = shipment_number || ''
        metadata.seal_numbers = prepareSealNumbers(seal_numbers)
        metadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (
        isChassisIdVisible(
          power_unit_type as PowerUnitTypes,
          cargo_asset_asset_type as CargoAssetTypes
        )
      ) {
        metadata.chassis_id = chassis_id || ''
      }
    }

    const query: CreateGateTransactionRequestBody = {
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckOut,
      type: DomainEventTypes.ManualCheckOut,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    try {
      await createGateTransaction({
        params,
        body: query
      }).unwrap()

      closeModal(true)
    } catch (e: any) {
      setErrorMsg(e?.data?.errors?.[0]?.message || 'Something went wrong')
    }
  }

  return (
    <Modal
      title="Check-Out"
      placement="fullScreen"
      closeModal={() => closeModal(false)}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      footer={
        <Button
          action="submit"
          disabled={isLoading}
          onClick={onSubmit}
          type="primary"
        >
          {isLoading ? 'Loading...' : 'Complete Check-Out'}
        </Button>
      }
    >
      <Snackbar
        type="error"
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)}
        message={errorMsg}
      />

      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!item}
              img={imageUrl}
              laneName={lane.display_name}
            />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <CheckOutPowerUnitFormSection siteId={site_id} orgId={org_id} />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection readOnly />
            </FormSection>

            {showCargoAsset && (
              <FormSection title="Cargo Asset">
                <CheckOutCargoAssetFormSection
                  siteId={site_id}
                  orgId={org_id}
                />
              </FormSection>
            )}
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CheckOutModal
