import React from 'react'

import { Col, Loader } from '@/components/atoms'
import { TitleWithDescription } from '@/components/molecules'
import { WithHeaderTemplate } from '@/components/templates'
import { Queues } from '@/features/gate'
import { useFetchGatesQuery, useFetchLanesQuery } from '@/features/gate/api'
import { useStore } from '@/store'

const GateQueueTemplate = () => {
  const { org, selectedPortal } = useStore((store) => store.user || {})

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const { data: gateResponse, isFetching: gatesLoading } = useFetchGatesQuery({
    org_id,
    site_id
  })

  const gate_id = gateResponse?.data?.gates?.[0]?.id || ''

  const { data: lanesResponse, isFetching: lanesLoading } = useFetchLanesQuery(
    { org_id, site_id, gate_id },
    { skip: !org_id || !site_id || !gate_id }
  )

  const loading = gatesLoading || lanesLoading

  const lanes = !gate_id ? [] : lanesResponse?.data?.lanes || []

  return (
    <WithHeaderTemplate title="Gate Queue">
      {loading || !lanes.length ? (
        <Col items="center" justify="center" gap={8} className="tw-h-full">
          {loading ? (
            <Loader />
          ) : (
            <TitleWithDescription
              title={!gate_id ? 'There is no gates' : 'There is no lanes'}
              description={
                !gate_id
                  ? 'The selected site does not contain any gates.'
                  : 'The selected site does not contain any lanes.'
              }
            />
          )}
        </Col>
      ) : (
        <Queues lanes={lanes} gateId={gate_id} />
      )}
    </WithHeaderTemplate>
  )
}

export default GateQueueTemplate
