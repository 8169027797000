import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'
import { CircularProgress, Collapse } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { CSSProperties, FC, useEffect, useRef, useState } from 'react'

import { Button, Col, Row } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { CardRow } from '@/features/gate'
import {
  useGetPresignedUrlQuery,
  useLazyGetCarrierQuery,
  useLazyGetClassAndFuelQuery,
  useLazyGetVinQuery
} from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import {
  EventExtraDetails,
  GateQueueEvent,
  GateQueueEventWithExtraDetails
} from '@/features/gate/types'
import { prepareLpnForCard } from '@/features/gate/utils'
import { convertStateFromTVE } from '@/utils/helpers'
import { weightClassesFriendlyNames } from '@/utils/mappers'

import styles from './QueueCard.module.scss'

interface IProps {
  item: GateQueueEvent
  type: LaneDirection
  onSwapLane: (item: GateQueueEventWithExtraDetails, to: LaneDirection) => void
  onCheckInOut: (item: GateQueueEventWithExtraDetails) => void
  onDelete: () => void
}

const QueueCard: FC<IProps> = (props) => {
  const { item, type, onSwapLane, onCheckInOut, onDelete } = props
  const { id, metadata, created_date, organization_id: org_id, site_id } = item

  const {
    AssetChainEvent,
    power_unit_owner_id,
    power_unit_license_plate_number,
    power_unit_license_plate_state,
    power_unit_carrier_usdot,
    cargo_asset_owner_id,
    cargo_asset_license_plate_number,
    cargo_asset_license_plate_state,
    chassis_id
  } = metadata

  const cardRef = useRef<any>()

  const [expanded, setExpanded] = useState(false)
  const [isInViewport, setIsInViewport] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const [loading, setLoading] = useState(false)
  const [extraDetails, setExtraDetails] = useState<EventExtraDetails>({
    vin: undefined,
    carrier: undefined,
    weightClass: undefined,
    fuelType: undefined
  })

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    {
      skip: !isInViewport,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false
    }
  )

  const [getCarrier] = useLazyGetCarrierQuery()
  const [getVin] = useLazyGetVinQuery()
  const [getClassAndFuel] = useLazyGetClassAndFuelQuery()

  const isCheckIn = type === LaneDirection.Arriving

  const getAssetChainMetadata = () => {
    const assetChainId = AssetChainEvent.asset_chain.id.split('-')
    const lastPartOfId = assetChainId[assetChainId.length - 1]

    const cameraId = AssetChainEvent.camera.id.split('-')
    const lastPartOfCameraId = cameraId[cameraId.length - 1]

    return `ID: ${lastPartOfId}, Camera ID: ${lastPartOfCameraId}`
  }

  const toggleDetails = () => {
    setExpanded(!expanded)

    if (expanded) {
      setAnimationInProgress(true)
    }
  }

  const fetchDetails = async () => {
    setLoading(() => true)

    try {
      if (power_unit_carrier_usdot?.length) {
        const carrierResponse = await getCarrier({
          org_id,
          us_dot: power_unit_carrier_usdot[0]
        })

        setExtraDetails((prev) => ({
          ...prev,
          carrier: carrierResponse?.data?.data?.carrier?.name || ''
        }))
      }

      const hasLpnAndState =
        !!power_unit_license_plate_state?.length &&
        !!power_unit_license_plate_number?.length

      if (hasLpnAndState) {
        const vinResponse = await getVin({
          org_id,
          license_plate_number: power_unit_license_plate_number[0],
          state: convertStateFromTVE(power_unit_license_plate_state[0])
        })

        setExtraDetails((prev) => ({
          ...prev,
          vin: vinResponse?.data?.data?.vin || ''
        }))

        if (vinResponse?.data?.data?.vin) {
          const classAndFuelResponse = await getClassAndFuel({
            org_id,
            vin: vinResponse.data.data.vin
          })

          setExtraDetails((prev) => ({
            ...prev,
            weightClass:
              classAndFuelResponse?.data?.data?.vehicle_class || undefined,
            fuelType: classAndFuelResponse?.data?.data?.fuel_type || undefined
          }))
        }
      }
    } finally {
      setLoading(() => false)
    }
  }

  const onButtonClick = () => {
    onCheckInOut({
      ...item,
      imageUrl,
      extraDetails
    })
  }

  const onSwap = () => {
    onSwapLane(
      {
        ...item,
        imageUrl,
        extraDetails
      },
      type === LaneDirection.Arriving
        ? LaneDirection.Departing
        : LaneDirection.Arriving
    )
  }

  useEffect(() => {
    if (isInViewport) {
      fetchDetails()
    }
  }, [
    power_unit_license_plate_number,
    power_unit_license_plate_state,
    isInViewport
  ])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInViewport(true)
        }
      },
      { threshold: 0.1 }
    )

    const card = cardRef.current

    if (card) {
      observer.observe(card)
    }

    return () => {
      if (card) {
        observer.unobserve(card)
      }
    }
  }, [])

  const ToggleButton = (
    <ToggleDetailsButton
      showDetails={expanded}
      className="!tw-px-0 tw-w-fit"
      onClick={toggleDetails}
    />
  )

  const Actions = (
    <Row items="stretch" justify="between" gap={16}>
      <Button
        type="secondary"
        startIcon={isCheckIn ? <LoginOutlinedIcon /> : <LogoutOutlinedIcon />}
        onClick={onButtonClick}
      >
        {isCheckIn ? 'Check-In' : 'Check-Out'}
      </Button>

      <Row items="stretch" gap={8}>
        <Button type="outlined" className={styles.swap} onClick={onSwap}>
          <SwapVertOutlinedIcon />
        </Button>

        <Button type="outlined" className={styles.bin} onClick={onDelete}>
          <DeleteOutlineOutlinedIcon />
        </Button>
      </Row>
    </Row>
  )

  return (
    <div className={styles.card} ref={cardRef}>
      {imageUrl ? (
        <div
          className={styles.imgWrapper}
          style={{ '--truck-image': `url(${imageUrl})` } as CSSProperties}
        />
      ) : (
        <Col items="center" justify="center" className={styles.imageLoader}>
          <CircularProgress color="secondary" />
        </Col>
      )}

      <Col items="stretch" className={styles.cardBody}>
        <span>{getAssetChainMetadata()}</span>
        <CardRow
          secondBold
          titleOne="Date"
          titleTwo="Time"
          valueOne={dayjs(created_date).format('MM/DD/YYYY')}
          valueTwo={dayjs(created_date).format('hh:mm A')}
        />

        <CardRow
          titleOne="Power Unit LPN"
          titleTwo="Power Unit ID"
          valueOne={prepareLpnForCard(
            power_unit_license_plate_number,
            power_unit_license_plate_state
          )}
          valueTwo={power_unit_owner_id?.[0]}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          onExited={() => setAnimationInProgress(false)}
          className={clsx(animationInProgress && '!tw-m-0')}
        >
          <CardRow
            titleOne="Class"
            titleTwo="Operating Carrier"
            valueOne={
              extraDetails.weightClass
                ? weightClassesFriendlyNames[extraDetails.weightClass]
                : undefined
            }
            valueTwo={extraDetails.carrier}
            loadingOne={loading}
            loadingTwo={loading}
          />
        </Collapse>

        <CardRow
          titleOne="Cargo Asset ID"
          titleTwo={expanded ? 'Cargo Asset LPN' : undefined}
          valueOne={cargo_asset_owner_id?.[0]}
          valueTwo={
            expanded
              ? prepareLpnForCard(
                  cargo_asset_license_plate_number,
                  cargo_asset_license_plate_state
                )
              : undefined
          }
          button={ToggleButton}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          className={clsx(animationInProgress && '!tw-m-0 tw-opacity-0')}
        >
          <CardRow
            titleOne="Chassis ID"
            titleTwo={undefined}
            valueOne={chassis_id?.[0]}
            valueTwo={undefined}
            button={ToggleButton}
          />
        </Collapse>

        {Actions}
      </Col>
    </div>
  )
}

export default QueueCard
