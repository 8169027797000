import { IUserPortalsListItem } from '@/features/auth/types'
import { ROUTES } from '@/router'
import { PortalTypes } from '@/types/enums/global'

interface IProps {
  selectedPortal: IUserPortalsListItem
  allowedLinks: string[]
  pathname: string
  siteIdFromUrlParam: string | undefined
}

const findPageToRedirect = (props: IProps) => {
  const { selectedPortal, allowedLinks, pathname, siteIdFromUrlParam } = props

  // Enterprise portal has landing page so initially user has to be redirected to that page
  if (
    selectedPortal.type === PortalTypes.Enterprise ||
    allowedLinks.length === 0
  )
    return ROUTES.HOME

  // Sometimes user may have access to the page but the site_id in URL is previously selected site_id,
  // so we need to replace it with the new site_id
  const samePage = siteIdFromUrlParam
    ? allowedLinks.find(
        (link) =>
          link === pathname.replace(siteIdFromUrlParam, selectedPortal.id)
      )
    : undefined

  return samePage || allowedLinks[0]
}

export default findPageToRedirect
