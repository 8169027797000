import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import {
  Autocomplete,
  Checkbox,
  Col,
  Input,
  Row,
  Select
} from '@/components/atoms'
import { SEAL_NUMBER_HELPER } from '@/constants'
import { useGetCarriersQuery } from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'
import { CargoAssetDetailsSchema } from '@/features/yard/utils'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import {
  cargoAssetTypeSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
  orgId: string
}

const CargoAssetFormSection: FC<IProps> = (props) => {
  const { accounts, orgId } = props

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CargoAssetDetailsSchema>>()

  const puType = watch('power_unit_type')
  const assetType = watch('cargo_asset_asset_type')
  const loadStatus = watch('load_status')
  const sealMatches = watch('seal_matchPW')
  const carrierDebounced = useDebounce(watch('cargo_asset_carrier_name'), 800)
  const inspectionCompleted = watch('inspection_completed')

  const { data: carriersResponse, isFetching: carriersLoading } =
    useGetCarriersQuery(
      {
        org_id: orgId,
        partial_name: carrierDebounced || ''
      },
      { skip: !carrierDebounced || carrierDebounced.length < 3 }
    )

  const carriersList = carriersResponse?.data?.carriers || []

  const isMinimized = isCargoAssetMinimized(puType)
  const isChassisVisible = isChassisIdVisible(puType, assetType)
  const isEmpty = isAssetEmpty(loadStatus)

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          <Controller
            name="cargo_asset_asset_type"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Asset Type"
                value={field.value}
                error={!!formState.errors.cargo_asset_asset_type}
                helperText={formState.errors.cargo_asset_asset_type?.message}
                options={cargoAssetTypeSelectOptions}
                onChange={field.onChange}
              />
            )}
          />

          <Row gap={16}>
            <Input
              {...register('cargo_asset_owner_id')}
              required
              uppercase
              fullWidth
              label="Asset ID"
              error={!!errors.cargo_asset_owner_id}
              helperText={errors.cargo_asset_owner_id?.message}
            />

            <Controller
              name="cargo_asset_carrier_name"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  required
                  fullWidth
                  label="Carrier"
                  inputValue={field.value}
                  options={carriersList || []}
                  loading={carriersLoading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onInputChange={(e, newValue) => field.onChange(newValue)}
                  getOptionLabel={(option: Carrier) => option?.name || ''}
                  customOptionLabel={(option: Carrier) =>
                    `${option.name} (${option.us_dot_number})`
                  }
                  isOptionEqualToValue={(option: Carrier) =>
                    option.name === field.value
                  }
                  onChange={(value: Carrier) => {
                    field.onChange(value?.name || '')
                  }}
                  inputProps={{
                    uppercase: true
                  }}
                />
              )}
            />
          </Row>
        </>
      )}

      <Col>
        <Row gap={16}>
          <Controller
            name="load_status"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Load Status"
                value={field.value}
                options={loadStatusSelectOptions}
                error={!!formState.errors.load_status}
                helperText={formState.errors.load_status?.message}
                onChange={field.onChange}
              />
            )}
          />

          {!isEmpty && (
            <Input
              {...register('shipment_number')}
              uppercase
              fullWidth
              label="Inbound Ref #"
              error={!!errors.shipment_number}
              helperText={errors.shipment_number?.message}
            />
          )}
        </Row>

        <Checkbox
          value={inspectionCompleted}
          label="Inspection complete"
          onChange={() =>
            setValue('inspection_completed', !inspectionCompleted)
          }
        />
      </Col>

      <Controller
        name="account_name"
        control={control}
        render={({ field, formState }) => (
          <Select
            label="Account"
            value={field.value}
            options={accounts}
            error={!!formState.errors.account_name}
            helperText={formState.errors.account_name?.message}
            onChange={field.onChange}
          />
        )}
      />

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Controller
            name="seal_numbers"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                fullWidth
                type="multiple"
                label="Seal #"
                value={field.value as string[]}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || SEAL_NUMBER_HELPER}
              />
            )}
          />

          <Checkbox
            value={sealMatches}
            label="The seal matches the paperwork "
            onChange={() => setValue('seal_matchPW', !sealMatches)}
          />
        </Col>
      )}

      {isChassisVisible && (
        <Input
          {...register('chassis_id')}
          uppercase
          fullWidth
          label="Chassis ID"
          error={!!errors.chassis_id}
          helperText={errors.chassis_id?.message}
        />
      )}

      {!isMinimized && (
        <Row gap={16}>
          <Input
            {...register('cargo_asset_license_plate_number')}
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.cargo_asset_license_plate_number}
            helperText={errors.cargo_asset_license_plate_number?.message}
          />

          <Controller
            name="cargo_asset_license_plate_state"
            control={control}
            render={({ field, formState }) => (
              <Select
                label="LPN State/Region"
                value={field.value}
                options={statesOptions}
                error={!!formState.errors.cargo_asset_license_plate_state}
                helperText={
                  formState.errors.cargo_asset_license_plate_state?.message
                }
                onChange={field.onChange}
              />
            )}
          />
        </Row>
      )}
    </Col>
  )
}

export default CargoAssetFormSection
