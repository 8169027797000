import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Snackbar } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CheckInCargoAssetFormSection,
  CheckInPowerUnitFormSection,
  DriverDetailsFormSection
} from '@/features/gate'
import {
  useCreateGateTransactionMutation,
  useFetchAccountsQuery
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  SealMatchesPW
} from '@/features/gate/enums'
import { GateQueueEventWithExtraDetails, Lane } from '@/features/gate/types'
import {
  CheckInSchema,
  getFormValuesFromQueueEvent,
  getSealMatchesPWValue,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible,
  isUsDOTVisible
} from '@/features/gate/utils'
import { useStore } from '@/store'
import { FuelTypes } from '@/types/enums/transactionDetails'
import { prepareSealNumbers } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface IProps {
  item?: GateQueueEventWithExtraDetails
  lane: Lane
  gateId: string
  closeModal: (removeFromMovedItems?: boolean) => void
}

const CheckInModal: FC<IProps> = (props) => {
  const { item, closeModal, lane, gateId } = props

  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()
  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const formReturn = useForm<yup.InferType<typeof CheckInSchema>>({
    resolver: yupResolver(CheckInSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getFormValuesFromQueueEvent(item)
  })

  const { watch, trigger, getValues } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch('appointment_type'),
    watch('power_unit_type')
  )

  const onSubmit = async () => {
    const valid = await trigger()

    if (!valid || !org_id || !site_id) return

    const {
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_carrier_name,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number,
      driver_license_state,

      cargo_asset_owner_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      cargo_asset_carrier_name,
      cargo_asset_asset_type,
      load_status,
      shipment_number,
      seal_numbers,
      chassis_id,
      seal_matchPW,

      inspection_completed
    } = getValues()

    const metadata: CreateGateTransactionMetadata = {
      lane_id: lane.id,
      transaction_type: DomainTransactionTypes.CheckIn,
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,

      power_unit_carrier_usdot: '',
      power_unit_carrier_name,
      power_unit_carrier_MCNum:
        item?.metadata?.power_unit_carrier_MCNum?.[0] || '',

      power_unit_vin: power_unit_vin || '',
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_emission_type:
        emissionTypeByFuelMapper[power_unit_fuel_type as FuelTypes],
      account_name: account_name ? [account_name] : [],

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number: driver_phone_number || '',
      driver_license_state,

      cargo_asset_owner_id: '',
      cargo_asset_license_plate_number: '',
      cargo_asset_license_plate_state: '',
      cargo_asset_carrier_name: '',
      cargo_asset_asset_type: '',
      load_status: '',
      shipment_number: '',
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      chassis_id: '',

      mismatch: false,
      inspection_completed: inspection_completed || null
    }

    if (isUsDOTVisible(appointment_type)) {
      metadata.power_unit_carrier_usdot = power_unit_carrier_usdot || ''
    }

    if (showCargoAsset) {
      metadata.load_status = load_status || ''

      if (!isCargoAssetMinimized(power_unit_type)) {
        metadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number || ''
        metadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state || ''
        metadata.cargo_asset_owner_id = cargo_asset_owner_id || ''
        metadata.cargo_asset_carrier_name = cargo_asset_carrier_name || ''
        metadata.cargo_asset_asset_type = cargo_asset_asset_type || ''
      }

      if (!isAssetEmpty(load_status)) {
        metadata.shipment_number = shipment_number || ''
        metadata.seal_numbers = prepareSealNumbers(seal_numbers)
        metadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (isChassisIdVisible(power_unit_type, cargo_asset_asset_type)) {
        metadata.chassis_id = chassis_id || ''
      }
    }

    const query: CreateGateTransactionRequestBody = {
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckIn,
      type: DomainEventTypes.ManualCheckIn,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    try {
      await createGateTransaction({
        params,
        body: query
      }).unwrap()

      closeModal(true)
    } catch (e: any) {
      setErrorMsg(e?.data?.errors?.[0]?.message || 'Something went wrong')
    }
  }

  return (
    <Modal
      title="Check-In"
      placement="fullScreen"
      closeModal={() => closeModal(false)}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      footer={
        <Button
          type="primary"
          action="submit"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {isLoading ? 'Loading...' : 'Complete Check-In'}
        </Button>
      }
    >
      <Snackbar
        type="error"
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)}
        message={errorMsg}
      />

      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!item}
              img={item?.imageUrl}
              laneName={lane.display_name}
            />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <CheckInPowerUnitFormSection
                accounts={accounts}
                suggestedIds={item?.metadata?.power_unit_owner_id || []}
                suggestedUsdot={item?.metadata?.power_unit_carrier_usdot || []}
                orgId={org_id}
              />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection />
            </FormSection>

            {showCargoAsset && (
              <FormSection title="Cargo Asset">
                <CheckInCargoAssetFormSection
                  orgId={org_id}
                  suggestedIds={item?.metadata?.cargo_asset_owner_id || []}
                />
              </FormSection>
            )}
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CheckInModal
