import { CircularProgress } from '@mui/material'
import { FC, useState } from 'react'
// @ts-ignore
import Lightbox from 'react-awesome-lightbox'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './FormAside.module.scss'

interface IProps {
  img: string | undefined
  laneName: string
  showImage: boolean
}

const FormAside: FC<IProps> = (props) => {
  const { img, laneName, showImage } = props

  const [showImagePreview, setShowImagePreview] = useState(false)

  const Image = img ? (
    <img
      src={img}
      alt="Check-In"
      className={styles.truckImg}
      onClick={() => setShowImagePreview(true)}
    />
  ) : (
    <Col items="center" justify="center" className={styles.truckImg}>
      <CircularProgress color="secondary" />
    </Col>
  )

  return (
    <>
      {showImagePreview && (
        <Lightbox image={img} onClose={() => setShowImagePreview(false)} />
      )}

      {showImage ? Image : undefined}

      <Col className={styles.metadata} gap={16}>
        <Col gap={5}>
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Lane
          </Text>
          <Text
            type={TextTypes.TEXT_LG}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {laneName}
          </Text>
        </Col>
      </Col>
    </>
  )
}

export default FormAside
