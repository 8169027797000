import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, Row, Select } from '@/components/atoms'
import { ISRVisitSchema } from '@/features/isr/utils'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import { classListSelectOptions, fuelTypeSelectOptions } from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
}

const VisitDetailsPowerUnit: FC<IProps> = (props) => {
  const { accounts } = props
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof ISRVisitSchema>>()

  return (
    <Col items="stretch" gap={20}>
      <Row gap={16}>
        <Input
          {...register('power_unit_owner_id')}
          uppercase
          fullWidth
          required
          label="ID"
          error={!!errors.power_unit_owner_id}
          helperText={errors.power_unit_owner_id?.message}
        />
        <Input
          {...register('power_unit_carrier_usdot')}
          fullWidth
          digitsOnly
          type="number"
          label="USDOT"
          error={!!errors.power_unit_carrier_usdot}
          helperText={errors.power_unit_carrier_usdot?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_license_plate_number')}
          uppercase
          fullWidth
          required
          label="LPN"
          error={!!errors.power_unit_license_plate_number}
          helperText={errors.power_unit_license_plate_number?.message}
        />

        <Controller
          name="power_unit_license_plate_state"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="LPN State/Region"
              value={field.value}
              options={statesOptions}
              error={!!formState.errors.power_unit_license_plate_state}
              helperText={
                formState.errors.power_unit_license_plate_state?.message
              }
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_vin')}
          uppercase
          fullWidth
          label="VIN"
          error={!!errors.power_unit_vin}
          helperText={errors.power_unit_vin?.message}
        />

        <Controller
          name="power_unit_weight_class"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Class"
              value={field.value}
              options={classListSelectOptions}
              error={!!formState.errors.power_unit_weight_class}
              helperText={formState.errors.power_unit_weight_class?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Controller
          name="power_unit_fuel_type"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Fuel Type"
              value={field.value}
              options={fuelTypeSelectOptions}
              error={!!formState.errors.power_unit_fuel_type}
              helperText={formState.errors.power_unit_fuel_type?.message}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name="account_name"
          control={control}
          render={({ field, formState }) => (
            <Select
              label="Account"
              value={field.value}
              options={accounts}
              error={!!formState.errors.account_name}
              helperText={formState.errors.account_name?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
    </Col>
  )
}

export default VisitDetailsPowerUnit
