import AddIcon from '@mui/icons-material/Add'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { BREAKPOINTS } from '@/constants'
import {
  CheckInModal,
  CheckOutModal,
  DeleteQueueItemModal,
  GateQueue
} from '@/features/gate'
import {
  useDeleteQueueEventMutation,
  useFetchQueueEventsQuery
} from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEventWithExtraDetails, Lane } from '@/features/gate/types'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { ITab } from '@/types/interfaces/ui'

import styles from './Queues.module.scss'

interface IProps {
  lanes: Lane[]
  gateId: string
}

const Queues: FC<IProps> = (props) => {
  const { lanes, gateId } = props

  const { org } = useStore((store) => store.user)
  const { organization_id = '' } = org || {}

  const [activeTab, setActiveTab] = useState<string | undefined>()
  // We store here cards moved from one lane to another since BE doesn't support this feature
  const [movedItems, setMovedItems] = useState<{
    [key in LaneDirection]: GateQueueEventWithExtraDetails[]
  }>({
    [LaneDirection.Arriving]: [],
    [LaneDirection.Departing]: []
  })
  const [selectedItem, setSelectedItem] = useState<{
    event: GateQueueEventWithExtraDetails | undefined
    lane: Lane | undefined
  }>({
    event: undefined,
    lane: undefined
  })

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.LG

  const { visible, ...modalHelpers } = useModal<LaneDirection | 'delete'>()

  const { arrivingLane, departingLane } = useMemo(
    () => ({
      arrivingLane: lanes.find(
        (lane) => lane.direction === LaneDirection.Arriving
      ),
      departingLane: lanes.find(
        (lane) => lane.direction === LaneDirection.Departing
      )
    }),
    [lanes]
  )

  const { data: arrivingData } = useFetchQueueEventsQuery(
    {
      org_id: organization_id,
      lane_id: arrivingLane?.id as string,
      direction: LaneDirection.Arriving
    },
    { skip: !organization_id || !arrivingLane?.id, pollingInterval: 5000 }
  )

  const { data: departingData } = useFetchQueueEventsQuery(
    {
      org_id: organization_id,
      lane_id: departingLane?.id as string,
      direction: LaneDirection.Departing
    },
    { skip: !organization_id || !departingLane?.id, pollingInterval: 5000 }
  )
  const [deleteEvent, { isLoading: isDeleting }] = useDeleteQueueEventMutation()

  const {
    arrivingCards,
    departingCards,
    totalArrivingCount,
    totalDepartingCount
  } = useMemo(() => {
    const arriving = arrivingData?.data || []
    const departing = departingData?.data || []

    return {
      arrivingCards: [
        ...movedItems[LaneDirection.Arriving],
        ...arriving.filter(
          (item) =>
            !movedItems[LaneDirection.Departing].some((i) => i.id === item.id)
        )
      ],

      departingCards: [
        ...movedItems[LaneDirection.Departing],
        ...departing.filter(
          (item) =>
            !movedItems[LaneDirection.Arriving].some((i) => i.id === item.id)
        )
      ],

      totalArrivingCount:
        (arrivingData?.pagination?.total_items_count || 0) +
        movedItems.ARRIVING.length -
        movedItems.DEPARTING.length,

      totalDepartingCount:
        (departingData?.pagination?.total_items_count || 0) +
        movedItems.DEPARTING.length -
        movedItems.ARRIVING.length
    }
  }, [movedItems, arrivingData, departingData])

  const openModal = (
    event: GateQueueEventWithExtraDetails | undefined,
    lane: Lane,
    deleteItem?: boolean
  ) => {
    setSelectedItem({ event, lane })
    modalHelpers.openModal(deleteItem ? 'delete' : lane.direction)
  }

  const closeModal = (removeFromMovedItems?: boolean) => {
    if (removeFromMovedItems) {
      setMovedItems((prev) => ({
        [LaneDirection.Arriving]: prev[LaneDirection.Arriving].filter(
          (i) => i.id !== selectedItem.event?.id
        ),
        [LaneDirection.Departing]: prev[LaneDirection.Departing].filter(
          (i) => i.id !== selectedItem.event?.id
        )
      }))
    }

    setSelectedItem({ event: undefined, lane: undefined })
    modalHelpers.closeModal()
  }

  const handleDelete = async (reason: string) => {
    if (!selectedItem.event || !gateId) return

    await deleteEvent({
      org_id: selectedItem.event.organization_id,
      gate_id: gateId,
      lane_id: selectedItem.event.lane_id,
      id: selectedItem.event.correlation_id,
      reason
    })

    closeModal(true)
  }

  const onManually = () => {
    if (!activeTab) return

    const selectedLane = lanes.find((lane) => lane.id === activeTab)

    if (!selectedLane) return

    openModal(undefined, selectedLane)
  }

  const onSwapLane = (
    item: GateQueueEventWithExtraDetails,
    to: LaneDirection
  ) => {
    const currentLane =
      to === LaneDirection.Arriving ? departingLane : arrivingLane
    const newLane = to === LaneDirection.Arriving ? arrivingLane : departingLane

    if (!newLane || !currentLane) return

    const currentLaneDirection = currentLane.direction

    const alreadyMovedItem = movedItems[currentLaneDirection].find(
      (i) => i.id === item.id
    )

    // If items is already moved to the opposite direction just remove it from there
    if (alreadyMovedItem) {
      setMovedItems((prev) => ({
        ...prev,
        [currentLaneDirection]: prev[currentLaneDirection].filter(
          (i) => i.id !== item.id
        )
      }))

      return
    }

    setMovedItems((prev) => ({
      ...prev,
      [to]: [item, ...prev[to]]
    }))
  }

  const CheckInQueue = useMemo(
    () =>
      arrivingLane ? (
        <GateQueue
          lane={arrivingLane}
          type={LaneDirection.Arriving}
          items={arrivingCards}
          onSwapLane={onSwapLane}
          openModal={openModal}
          totalCount={totalArrivingCount}
        />
      ) : undefined,
    [arrivingLane, arrivingCards]
  )

  const CheckOutQueue = useMemo(
    () =>
      departingLane ? (
        <GateQueue
          lane={departingLane}
          type={LaneDirection.Departing}
          items={departingCards}
          onSwapLane={onSwapLane}
          openModal={openModal}
          totalCount={totalDepartingCount}
        />
      ) : undefined,
    [departingLane, departingCards]
  )

  const tabs: ITab[] = [
    ...(arrivingLane
      ? [
          {
            id: arrivingLane.id,
            title: 'Check-In',
            icon: <LoginOutlinedIcon />,
            items: totalArrivingCount,
            Component: CheckInQueue
          }
        ]
      : []),

    ...(departingLane
      ? [
          {
            id: departingLane.id,
            title: 'Check-Out',
            icon: <LogoutOutlinedIcon />,
            items: totalDepartingCount,
            Component: CheckOutQueue
          }
        ]
      : [])
  ]

  useEffect(() => {
    setActiveTab(arrivingLane?.id || departingLane?.id)
  }, [arrivingLane, departingLane])

  return (
    <>
      {visible === LaneDirection.Arriving && (
        <CheckInModal
          gateId={gateId}
          item={selectedItem.event}
          lane={selectedItem.lane as Lane}
          closeModal={closeModal}
        />
      )}

      {visible === LaneDirection.Departing && (
        <CheckOutModal
          gateId={gateId}
          item={selectedItem.event}
          lane={selectedItem.lane as Lane}
          closeModal={closeModal}
        />
      )}

      {visible === 'delete' && (
        <DeleteQueueItemModal
          loading={isDeleting}
          closeModal={closeModal}
          onDelete={handleDelete}
        />
      )}

      {isSmallView ? (
        <div className="tw-flex-1 tw-flex">
          <Tabs
            countAsBadge
            active={activeTab}
            setActive={setActiveTab}
            tabs={tabs}
            actions={
              <Button type="outlined" onClick={onManually}>
                <AddIcon />
              </Button>
            }
          />
        </div>
      ) : (
        <div
          className={styles.queues}
          // We need this to dynamically generate the grid columns
          style={{ '--count': lanes.length || 1 } as CSSProperties}
        >
          {CheckInQueue}
          {CheckOutQueue}
        </div>
      )}
    </>
  )
}

export default Queues
