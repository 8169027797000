import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Chip } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  useFetchAccountsQuery,
  useGetPresignedUrlQuery
} from '@/features/gate/api'
import {
  CargoAssetFormSection,
  PowerUnitAndDriverDetails
} from '@/features/yard'
import { CargoAsset } from '@/features/yard/types'
import { CargoAssetDetailsSchema } from '@/features/yard/utils'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { sealMatchesMapper } from '@/utils/mappers'

interface IProps extends IModalWithCloseFn {
  item: CargoAsset
}

const CargoAssetDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { metadata, id, reference_id } = item
  const { mismatch } = metadata

  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    { skip: !reference_id }
  )

  const formReturn = useForm<yup.InferType<typeof CargoAssetDetailsSchema>>({
    resolver: yupResolver(CargoAssetDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      date: new Date(item.created_date),
      appointment_type: item.metadata.appointment_type,

      power_unit_type: item.metadata.power_unit_type,
      account_name: item.metadata.account_name?.[0],

      cargo_asset_asset_type: item.metadata.cargo_asset_asset_type,
      cargo_asset_owner_id: item.metadata.cargo_asset_owner_id,
      cargo_asset_carrier_name: item.metadata.cargo_asset_carrier_name,
      chassis_id: item.metadata.chassis_id,
      cargo_asset_license_plate_number:
        item.metadata.cargo_asset_license_plate_number,
      cargo_asset_license_plate_state:
        item.metadata.cargo_asset_license_plate_state,
      load_status: item.metadata.load_status,
      shipment_number: item.metadata.shipment_number,
      seal_numbers: item.metadata.seal_numbers || [],
      seal_matchPW: sealMatchesMapper[item.metadata.seal_matchPW],
      inspection_completed: item.metadata.inspection_completed
    }
  })

  const { trigger } = formReturn

  const onSubmit = () => {
    trigger()
  }

  return (
    <Modal
      title="Cargo Asset Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button action="submit" onClick={onSubmit} type="primary">
          Save Changes
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!reference_id}
              img={imageUrl}
              laneName="Lane 1"
            />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Cargo Asset">
              <CargoAssetFormSection orgId={org_id} accounts={accounts} />
            </FormSection>

            <FormSection title="Power Unit and Driver Details">
              <PowerUnitAndDriverDetails item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CargoAssetDetailsModal
