import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, Radio, Row, Select, Text } from '@/components/atoms'
import { SEAL_NUMBER_HELPER } from '@/constants'
import { ISRVisitSchema } from '@/features/isr/utils'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { statesOptions } from '@/utils/data'

interface IProps {
  type: 'check_in' | 'check_out'
}

const VisitDetailsCargoAsset: FC<IProps> = (props) => {
  const { type } = props

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof ISRVisitSchema>>()

  const title = type === 'check_in' ? 'Check-In' : 'Check-Out'

  const matches = watch(`${type}_seal_matchPW`)

  const toggleMatchesValue = (value: boolean) => {
    setValue(`${type}_seal_matchPW`, value)
  }

  return (
    <Col items="stretch" gap={20}>
      <Text
        type={TextTypes.TEXT_SM}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {title}
      </Text>

      <Row gap={16}>
        <Input
          {...register(`${type}_cargo_asset_owner_id`)}
          uppercase
          fullWidth
          label="ID"
          error={!!errors[`${type}_cargo_asset_owner_id`]}
          helperText={errors[`${type}_cargo_asset_owner_id`]?.message}
        />
        <Input
          {...register(`${type}_shipment_number`)}
          uppercase
          fullWidth
          label="Shipment #"
          error={!!errors[`${type}_shipment_number`]}
          helperText={errors[`${type}_shipment_number`]?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register(`${type}_cargo_asset_license_plate_number`)}
          uppercase
          fullWidth
          label="LPN"
          error={!!errors[`${type}_cargo_asset_license_plate_number`]}
          helperText={
            errors[`${type}_cargo_asset_license_plate_number`]?.message
          }
        />

        <Controller
          name={`${type}_cargo_asset_license_plate_state`}
          control={control}
          render={({ field, formState }) => (
            <Select
              label="LPN State/Region"
              value={field.value}
              options={statesOptions}
              error={
                !!formState.errors[`${type}_cargo_asset_license_plate_state`]
              }
              helperText={
                formState.errors[`${type}_cargo_asset_license_plate_state`]
                  ?.message
              }
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Controller
          name={`${type}_seal_numbers`}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              fullWidth
              type="multiple"
              label="Seal #"
              value={field.value as string[]}
              onChange={field.onChange}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || SEAL_NUMBER_HELPER}
            />
          )}
        />

        <Col gap={2} className="tw-w-full">
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Seal Matches PW
          </Text>

          <Row items="center" gap={6}>
            <Radio
              label="Yes"
              checked={matches === true}
              onChange={() => toggleMatchesValue(true)}
            />
            <Radio
              label="No"
              checked={matches === false}
              onChange={() => toggleMatchesValue(false)}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default VisitDetailsCargoAsset
