import { FC, useState } from 'react'
// @ts-ignore
import Lightbox from 'react-awesome-lightbox'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './VisitDetailsImages.module.scss'

interface IProps {
  checkInImg?: string
  checkOutImg?: string
}

const VisitDetailsImages: FC<IProps> = (props) => {
  const { checkInImg, checkOutImg } = props

  const [previewImg, setPreviewImg] = useState<string | undefined>()

  const renderImgBlock = (title: string, img: string | undefined) => (
    <Col items="stretch" className="tw-w-full">
      <Text
        className={styles.imgHeader}
        type={TextTypes.TEXT_MD}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {title}
      </Text>
      {img ? (
        <img
          src={img}
          alt={title}
          onClick={() => setPreviewImg(img)}
          className={styles.truckImg}
        />
      ) : (
        <div className={styles.truckImg} />
      )}
    </Col>
  )

  return (
    <>
      {!!previewImg && (
        <Lightbox
          showTitle
          title="Check-In"
          image={previewImg}
          onClose={() => setPreviewImg(undefined)}
        />
      )}

      {renderImgBlock('Check-In', checkInImg)}
      {renderImgBlock('Check-Out', checkOutImg)}
    </>
  )
}

export default VisitDetailsImages
