import SearchIcon from '@mui/icons-material/Search'
import { ChangeEvent, FC } from 'react'

import { Col, Input, Row } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  FilterDropdown,
  GroupByDropdown
} from '@/components/organisms/Table/components'

import styles from './TableFilters.module.scss'

interface IProps {
  withSearch: boolean
}

const TableFilters: FC<IProps> = (props) => {
  const { withSearch } = props
  const {
    filters,
    searchValue,
    searchPlaceholder,
    groupByOptions,
    onSearchChange
  } = useTableContext()

  const withFilters = Array.isArray(filters)

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value ? e.target.value : undefined)
  }

  return (
    <Col>
      <div id="filters-portal" className="tw-overflow-y-auto" />
      <Row
        justify="between"
        gap={20}
        items="center"
        className={styles.tableFilters}
      >
        {(withFilters || withSearch) && (
          <Row gap={8}>
            {withSearch && (
              <Input
                size="small"
                type="text"
                variant="outlined"
                value={searchValue}
                onChange={onSearch}
                placeholder={searchPlaceholder}
                className="background-color-gray0"
                icon={<SearchIcon width={16} height={16} />}
              />
            )}

            {withFilters && <FilterDropdown />}
          </Row>
        )}

        {Array.isArray(groupByOptions) && <GroupByDropdown />}
      </Row>
    </Col>
  )
}

export default TableFilters
