import clsx from 'clsx'
import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react'

import { Col, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'

import styles from './Tabs.module.scss'

interface IProps {
  active: string | undefined
  setActive: Dispatch<SetStateAction<any>>
  countAsBadge?: boolean
  tabs: ITab[]
  actions?: ReactNode
  headerClassName?: string
}

const Tabs: FC<IProps> = (props) => {
  const { tabs, active, countAsBadge, setActive, actions, headerClassName } =
    props

  const activeTabComponent = useMemo(
    () =>
      active ? tabs.find((tab) => tab.id === active)?.Component || null : null,
    [tabs, active]
  )

  return (
    <Col items="stretch" className={styles.tabsWrapper}>
      <Row items="stretch" className={clsx(styles.tabsHeader, headerClassName)}>
        {tabs.map((tab) => (
          <Row
            key={tab.id}
            gap={4}
            items="center"
            onClick={() => setActive(tab.id)}
            className={clsx(styles.tabItem, active === tab.id && styles.active)}
          >
            {tab.icon}

            <Text
              type={TextTypes.TEXT_SM}
              color={active === tab.id ? Color.green500 : Color.gray600}
            >
              {tab.title} {!countAsBadge && `(${tab.items})`}
            </Text>

            {countAsBadge && (
              <span className={styles.count}>
                <Text
                  type={TextTypes.TEXT_SM}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {tab.items}
                </Text>
              </span>
            )}
          </Row>
        ))}

        {!!actions && <div className={styles.actionsWrapper}>{actions}</div>}
      </Row>

      <div className={styles.tabsContent}>{activeTabComponent}</div>
    </Col>
  )
}

export default Tabs
