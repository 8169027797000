import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Chip, Col, Text } from '@/components/atoms'
import { FormSection, TwoColumnModalWrapper } from '@/components/molecules'
import { Modal } from '@/components/organisms'
import { DriverDetailsFormSection } from '@/features/gate'
import {
  useFetchAccountsQuery,
  useGetPresignedUrlQuery,
  useLazyFetchGatesQuery
} from '@/features/gate/api'
import { DomainEventSchema, DomainEventTypes } from '@/features/gate/enums'
import { getSealMatchesPWValue } from '@/features/gate/utils'
import { VisitDetailsImages } from '@/features/isr'
import { useUpdateISRRecordMutation } from '@/features/isr/api'
import {
  UpdateISRRecordRequestBody,
  UpdateISRRecordRequestParams
} from '@/features/isr/api/types'
import { ISRRecord } from '@/features/isr/types'
import { ISRVisitSchema } from '@/features/isr/utils'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { prepareSealNumbers } from '@/utils/helpers'
import { emissionTypeByFuelMapper, sealMatchesMapper } from '@/utils/mappers'

import {
  VisitDetailsCargoAsset,
  VisitDetailsGeneral,
  VisitDetailsPowerUnit
} from './formSections'
import styles from './ISRDetailsModal.module.scss'

interface IProps extends IModalWithCloseFn {
  item: ISRRecord
}

const ISRDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props
  const {
    site_id,
    organization_id,
    outbound_correlation_id,
    inbound_correlation_id,
    inbound_event_id,
    outbound_event_id,
    outbound_metadata,
    inbound_metadata,
    power_unit_license_plate_number,
    power_unit_license_plate_state
  } = item

  const { mismatch } = outbound_metadata

  const [updateISRRecord] = useUpdateISRRecordMutation()
  const [fetchGates] = useLazyFetchGatesQuery()
  const [loading, setLoading] = useState(false)

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id: organization_id
  })
  const { data: inboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organization_id,
      site_id,
      event_id: inbound_event_id
    },
    { skip: !inbound_event_id }
  )
  const { data: outboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organization_id,
      site_id,
      event_id: outbound_event_id
    },
    { skip: !outbound_event_id }
  )

  const formState = useForm<yup.InferType<typeof ISRVisitSchema>>({
    resolver: yupResolver(ISRVisitSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      power_unit_owner_id: inbound_metadata.power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot: inbound_metadata.power_unit_carrier_usdot,
      power_unit_vin: inbound_metadata.power_unit_vin,
      power_unit_weight_class: inbound_metadata.power_unit_weight_class,
      power_unit_fuel_type: inbound_metadata.power_unit_fuel_type,
      account_name: inbound_metadata.account_name?.[0],

      driver_first_name: inbound_metadata.driver_first_name,
      driver_last_name: inbound_metadata.driver_last_name,
      driver_license_number: inbound_metadata.driver_license_number,
      driver_phone_number: inbound_metadata.driver_phone_number,
      driver_license_state: inbound_metadata.driver_license_state,

      check_in_cargo_asset_owner_id: inbound_metadata.cargo_asset_owner_id,
      check_in_cargo_asset_license_plate_number:
        inbound_metadata.cargo_asset_license_plate_number,
      check_in_cargo_asset_license_plate_state:
        inbound_metadata.cargo_asset_license_plate_state,
      check_in_shipment_number: inbound_metadata.shipment_number,
      check_in_seal_numbers: inbound_metadata.seal_numbers,
      check_in_seal_matchPW: sealMatchesMapper[inbound_metadata.seal_matchPW],

      check_out_cargo_asset_owner_id: outbound_metadata.cargo_asset_owner_id,
      check_out_cargo_asset_license_plate_number:
        outbound_metadata.cargo_asset_license_plate_number,
      check_out_cargo_asset_license_plate_state:
        outbound_metadata.cargo_asset_license_plate_state,
      check_out_shipment_number: outbound_metadata.shipment_number,
      check_out_seal_numbers: outbound_metadata.seal_numbers,
      check_out_seal_matchPW: sealMatchesMapper[outbound_metadata.seal_matchPW]
    }
  })

  const {
    trigger,
    getValues,
    formState: { isDirty }
  } = formState

  const onSubmit = async () => {
    if (!isDirty) {
      closeModal()
      return
    }

    const valid = await trigger()

    if (!valid || !organization_id || !site_id) return

    const values = getValues()

    try {
      setLoading(() => true)

      const gate = await fetchGates({
        org_id: organization_id,
        site_id
      })

      const gate_id = gate?.data?.data?.gates?.[0]?.id

      if (!gate_id) {
        throw new Error('Gate not found')
      }

      const commonMetadata = {
        power_unit_owner_id: values.power_unit_owner_id,
        power_unit_license_plate_number: values.power_unit_license_plate_number,
        power_unit_license_plate_state: values.power_unit_license_plate_state,
        power_unit_carrier_usdot: values.power_unit_carrier_usdot || '',
        power_unit_vin: values.power_unit_vin || '',
        power_unit_weight_class: values.power_unit_weight_class,
        power_unit_fuel_type: values.power_unit_fuel_type,
        power_unit_emission_type:
          emissionTypeByFuelMapper[values.power_unit_fuel_type],
        account_name: values.account_name ? [values.account_name] : [],

        driver_first_name: values.driver_first_name,
        driver_last_name: values.driver_last_name,
        driver_license_number: values.driver_license_number,
        driver_phone_number: values.driver_phone_number || '',
        driver_license_state: values.driver_license_state
      }

      const params: UpdateISRRecordRequestParams = {
        org_id: organization_id,
        site_id,
        gate_id,
        lane_id: inbound_metadata.lane_id
      }

      const body: UpdateISRRecordRequestBody = {
        license_plate_number: values.power_unit_license_plate_number,
        license_plate_state: values.power_unit_license_plate_state,
        records: [
          {
            correlation_id: inbound_correlation_id,
            event_type: DomainEventTypes.ManualISRRecordUpdate,
            schema: DomainEventSchema.September2024,
            metadata: {
              ...inbound_metadata,
              ...commonMetadata,

              cargo_asset_owner_id: values.check_in_cargo_asset_owner_id || '',
              cargo_asset_license_plate_number:
                values.check_in_cargo_asset_license_plate_number || '',
              cargo_asset_license_plate_state:
                values.check_in_cargo_asset_license_plate_state || '',
              shipment_number: values.check_in_shipment_number || '',
              seal_numbers: prepareSealNumbers(
                values.check_in_seal_numbers as string[]
              ),
              seal_matchPW: getSealMatchesPWValue(
                values.check_in_seal_matchPW,
                values.check_in_seal_numbers as string[]
              )
            }
          },
          {
            correlation_id: outbound_correlation_id,
            event_type: DomainEventTypes.ManualISRRecordUpdate,
            schema: DomainEventSchema.September2024,
            metadata: {
              ...outbound_metadata,
              ...commonMetadata,

              cargo_asset_owner_id: values.check_out_cargo_asset_owner_id || '',
              cargo_asset_license_plate_number:
                values.check_out_cargo_asset_license_plate_number || '',
              cargo_asset_license_plate_state:
                values.check_out_cargo_asset_license_plate_state || '',
              shipment_number: values.check_out_shipment_number || '',
              seal_numbers: prepareSealNumbers(
                values.check_out_seal_numbers as string[]
              ),
              seal_matchPW: getSealMatchesPWValue(
                values.check_out_seal_matchPW,
                values.check_out_seal_numbers as string[]
              )
            }
          }
        ]
      }

      await updateISRRecord({
        params,
        body
      })

      closeModal()
    } catch {
      // show toast with error
    } finally {
      setLoading(() => false)
    }
  }

  return (
    <>
      <Modal
        placement="fullScreen"
        title="Visit Details"
        closeModal={closeModal}
        bodyClassName={styles.modal}
        headerClassName="!tw-bg-transparent"
        header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
        footer={
          <Button type="primary" disabled={loading} onClick={onSubmit}>
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        }
        footerLeft={
          <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
            Changes will only be reflected on the ISR record.
          </Text>
        }
      >
        <TwoColumnModalWrapper
          leftSide={
            <VisitDetailsImages
              checkInImg={inboundImage}
              checkOutImg={outboundImage}
            />
          }
        >
          <FormProvider {...formState}>
            <form>
              <FormSection title="General">
                <VisitDetailsGeneral item={item} />
              </FormSection>

              <FormSection title="Power Unit">
                <VisitDetailsPowerUnit accounts={accounts} />
              </FormSection>

              <FormSection title="Driver Details">
                <DriverDetailsFormSection />
              </FormSection>

              <FormSection title="Cargo Asset">
                <Col items="stretch" gap={40}>
                  <VisitDetailsCargoAsset type="check_in" />
                  <VisitDetailsCargoAsset type="check_out" />
                </Col>
              </FormSection>
            </form>
          </FormProvider>
        </TwoColumnModalWrapper>
      </Modal>
    </>
  )
}

export default ISRDetailsModal
